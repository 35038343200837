<template>
  <v-card>
    <v-card-title>{{ dashCollection.name }}</v-card-title>
    <v-card-text class="pt-4 pb-0">
      <v-text-field
        :label="$t('email')"
        v-model="email"
        outlined
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
        large
        class="primary"
        block
        :loading="processing"
        :disabled="processing"
				@click="send"
      >
        {{ $t('send') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
	name: 'SendDashReportForm',
	props: {
		dashCollection: {
			required: true,
			type: Object
		},
		startTimeInMillis: {
			required: true,
			type: Number
		},
		endTimeInMillis: {
			required: true,
			type: Number
		}
	},
	data() {
		return {
			email: null,
			processing: false
		}
	},
	methods: {
		send() {
			this.processing = true
			this.$store.dispatch('sendDashReport', {
				dashCollectionUUID: this.dashCollection.uuid,
				startTimeInMillis: this.startTimeInMillis,
				endTimeInMillis: this.endTimeInMillis,
				email: this.email
			}).finally(() => {
				this.processing = false
				this.$store.commit('updateGenericDialog', null)
			})
		}
	}
}
</script>
